<template>
  <div>
    <h2 class="mb-4">Produktionsplanung KW {{ currentWeek }}</h2>

    <div class="mb-4 flex items-end gap-4">
      <label>
        Kalenderwoche
        <input
          type="number"
          max="53"
          min="1"
          v-model="currentWeek"
          style="max-width: 100px;"
        />
      </label>
      <input
        type="date"
        name="vonDatum"
        id="vonDatum"
        :value="dateByWeek.fromDate"
      />
      <input
        type="date"
        name="bisDatum"
        id="bisDatum"
        :value="dateByWeek.toDate"
      />
    </div>
    <div
      class="grid gap-2 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6"
      :class="'2xl:grid-cols-' + arbeitsgaenge.length"
    >
      <div class="list-group-container">
        <h3>{{ arbeitsgaenge[0].name }}</h3>
        <draggable
          :move="checkMove"
          :id="arbeitsgaenge[0].id"
          :list="listen.liste1"
          class="list-group"
          draggable=".item"
          group="a"
          @end="onEnd"
        >
          <router-link
            v-for="(element, index) in listen.liste1"
            :to="element.id"
            :key="element.id"
          >
            <div class="list-group-item item">
              <h4
                :class="[
                  element.rohlinge && 'rohlinge',
                  element.eilig && 'eilig',
                  element.material === 'gk' && 'gk'
                ]"
                @mouseover="showEditMenu(index)"
              >
                {{ element.belegnr }}
              </h4>
              <ul>
                <li>{{ element.kunde }}</li>
                <li>{{ element.form }} ø{{ element.durchmesser }}</li>
                <li>{{ element.menge }} Stück</li>
              </ul>
            </div>
          </router-link>
        </draggable>

        <p>
          Bearbeitungszeit: 2 Stunden
        </p>
        Aufträge: {{ listen.liste1.length }}
      </div>

      <div class="list-group-container">
        <h3>{{ arbeitsgaenge[1].name }}</h3>
        <draggable
          :move="checkMove"
          :id="arbeitsgaenge[1].id"
          :list="listen.liste2"
          class="list-group"
          draggable=".item"
          group="a"
          @end="onEnd"
        >
          <router-link
            v-for="(element, index) in listen.liste2"
            :to="`/pa/${element.auftragsnr}`"
            :key="element.id"
          >
            <div class="list-group-item item">
              <h4
                :class="[
                  element.rohlinge && 'rohlinge',
                  element.eilig && 'eilig',
                  element.material === 'gk' && 'gk'
                ]"
                @mouseover="showEditMenu(index)"
              >
                {{ element.belegnr }}
              </h4>
              <ul>
                <li>{{ element.kunde }}</li>
                <li>{{ element.form }} ø{{ element.durchmesser }}</li>
                <li>{{ element.menge }} Stück</li>
              </ul>
            </div>
          </router-link>
        </draggable>

        <p>
          Bearbeitungszeit: 2 Stunden
        </p>
        Aufträge: {{ listen.liste2.length }}
      </div>

      <div class="list-group-container">
        <h3>{{ arbeitsgaenge[2].name }}</h3>
        <draggable
          :move="checkMove"
          :id="arbeitsgaenge[2].id"
          :list="listen.liste3"
          class="list-group"
          draggable=".item"
          group="a"
          @end="onEnd"
        >
          <div
            class="list-group-item item"
            v-for="(element, index) in listen.liste3"
            :key="element.id"
          >
            <h4
              :class="[
                element.rohlinge && 'rohlinge',
                element.eilig && 'eilig',
                element.material === 'gk' && 'gk'
              ]"
              @mouseover="showEditMenu(index)"
            >
              {{ element.belegnr }}
            </h4>
            <ul>
              <li>{{ element.kunde }}</li>
              <li>{{ element.form }} ø{{ element.durchmesser }}</li>
              <li>{{ element.menge }} Stück</li>
            </ul>
          </div>
        </draggable>

        <p>
          Bearbeitungszeit: 2 Stunden
        </p>
        Aufträge: {{ listen.liste3.length }}
      </div>

      <div class="list-group-container">
        <h3>{{ arbeitsgaenge[3].name }}</h3>
        <draggable
          :move="checkMove"
          :id="arbeitsgaenge[3].id"
          :list="listen.liste4"
          class="list-group"
          draggable=".item"
          group="a"
          @end="onEnd"
        >
          <div
            class="list-group-item item"
            v-for="(element, index) in listen.liste4"
            :key="element.id"
          >
            <h4
              :class="[
                element.rohlinge && 'rohlinge',
                element.eilig && 'eilig',
                element.material === 'gk' && 'gk'
              ]"
              @mouseover="showEditMenu(index)"
            >
              {{ element.belegnr }}
            </h4>
            <ul>
              <li>{{ element.kunde }}</li>
              <li>{{ element.form }} ø{{ element.durchmesser }}</li>
              <li>{{ element.menge }} Stück</li>
            </ul>
          </div>
        </draggable>

        <p>
          Bearbeitungszeit: 2 Stunden
        </p>
        Aufträge: {{ listen.liste4.length }}
      </div>

      <div class="list-group-container">
        <h3>{{ arbeitsgaenge[4].name }}</h3>
        <draggable
          :move="checkMove"
          :id="arbeitsgaenge[4].id"
          :list="listen.liste5"
          class="list-group"
          draggable=".item"
          group="a"
          @end="onEnd"
        >
          <div
            class="list-group-item item"
            v-for="(element, index) in listen.liste5"
            :key="element.id"
          >
            <h4
              :class="[
                element.rohlinge && 'rohlinge',
                element.eilig && 'eilig',
                element.material === 'gk' && 'gk'
              ]"
              @mouseover="showEditMenu(index)"
            >
              {{ element.belegnr }}
            </h4>
            <ul>
              <li>{{ element.kunde }}</li>
              <li>{{ element.form }} ø{{ element.durchmesser }}</li>
              <li>{{ element.menge }} Stück</li>
            </ul>
          </div>
        </draggable>

        <p>
          Bearbeitungszeit: 2 Stunden
        </p>
        Aufträge: {{ listen.liste5.length }}
      </div>

      <div class="list-group-container">
        <h3>{{ arbeitsgaenge[5].name }}</h3>
        <draggable
          :move="checkMove"
          :id="arbeitsgaenge[5].id"
          :list="listen.liste6"
          class="list-group"
          draggable=".item"
          group="a"
          @end="onEnd"
        >
          <div
            class="list-group-item item"
            v-for="(element, index) in listen.liste6"
            :key="element.id"
          >
            <h4
              :class="[
                element.rohlinge && 'rohlinge',
                element.eilig && 'eilig',
                element.material === 'gk' && 'gk'
              ]"
              @mouseover="showEditMenu(index)"
            >
              {{ element.belegnr }}
            </h4>
            <ul>
              <li>{{ element.kunde }}</li>
              <li>{{ element.form }} ø{{ element.durchmesser }}</li>
              <li>{{ element.menge }} Stück</li>
            </ul>
          </div>
        </draggable>

        <p>
          Bearbeitungszeit: 2 Stunden
        </p>
        Aufträge: {{ listen.liste6.length }}
      </div>

      <div class="list-group-container">
        <h3>{{ arbeitsgaenge[6].name }}</h3>
        <draggable
          :move="checkMove"
          :id="arbeitsgaenge[6].id"
          :list="listen.liste7"
          class="list-group"
          draggable=".item"
          group="a"
          @end="onEnd"
        >
          <div
            class="list-group-item item"
            v-for="(element, index) in listen.liste7"
            :key="element.id"
          >
            <h4
              :class="[
                element.rohlinge && 'rohlinge',
                element.eilig && 'eilig',
                element.material === 'gk' && 'gk'
              ]"
              @mouseover="showEditMenu(index)"
            >
              {{ element.belegnr }}
            </h4>
            <ul>
              <li>{{ element.kunde }}</li>
              <li>{{ element.form }} ø{{ element.durchmesser }}</li>
              <li>{{ element.menge }} Stück</li>
            </ul>
          </div>
        </draggable>

        <p>
          Bearbeitungszeit: 2 Stunden
        </p>
        Aufträge: {{ listen.liste7.length }}
      </div>

      <div class="list-group-container">
        <h3>{{ arbeitsgaenge[7].name }}</h3>
        <draggable
          :move="checkMove"
          :id="arbeitsgaenge[7].id"
          :list="listen.liste8"
          class="list-group"
          draggable=".item"
          group="a"
          @end="onEnd"
        >
          <div
            class="list-group-item item"
            v-for="(element, index) in listen.liste8"
            :key="element.id"
          >
            <h4
              :class="[
                element.rohlinge && 'rohlinge',
                element.eilig && 'eilig',
                element.material === 'gk' && 'gk'
              ]"
              @mouseover="showEditMenu(index)"
            >
              {{ element.belegnr }}
            </h4>
            <ul>
              <li>{{ element.kunde }}</li>
              <li>{{ element.form }} ø{{ element.durchmesser }}</li>
              <li>{{ element.menge }} Stück</li>
            </ul>
          </div>
        </draggable>

        <p>
          Bearbeitungszeit: 2 Stunden
        </p>
        Aufträge: {{ listen.liste8.length }}
      </div>

      <div class="list-group-container">
        <h3>{{ arbeitsgaenge[8].name }}</h3>
        <draggable
          :move="checkMove"
          :id="arbeitsgaenge[8].id"
          :list="listen.liste9"
          class="list-group"
          draggable=".item"
          group="a"
          @end="onEnd"
        >
          <div
            class="list-group-item item"
            v-for="(element, index) in listen.liste9"
            :key="element.id"
          >
            <h4
              :class="[
                element.rohlinge && 'rohlinge',
                element.eilig && 'eilig',
                element.material === 'gk' && 'gk'
              ]"
              @mouseover="showEditMenu(index)"
            >
              {{ element.belegnr }}
            </h4>
            <ul>
              <li>{{ element.kunde }}</li>
              <li>{{ element.form }} ø{{ element.durchmesser }}</li>
              <li>{{ element.menge }} Stück</li>
            </ul>
          </div>
        </draggable>

        <p>
          Bearbeitungszeit: 2 Stunden
        </p>
        Aufträge: {{ listen.liste9.length }}
      </div>

      <div class="list-group-container">
        <h3>{{ arbeitsgaenge[9].name }}</h3>
        <draggable
          :move="checkMove"
          :id="arbeitsgaenge[9].id"
          :list="listen.liste10"
          class="list-group"
          draggable=".item"
          group="a"
          @end="onEnd"
        >
          <div
            class="list-group-item item"
            v-for="(element, index) in listen.liste10"
            :key="element.id"
          >
            <h4
              :class="[
                element.rohlinge && 'rohlinge',
                element.eilig && 'eilig',
                element.material === 'gk' && 'gk'
              ]"
              @mouseover="showEditMenu(index)"
            >
              {{ element.belegnr }}
            </h4>
            <ul>
              <li>{{ element.kunde }}</li>
              <li>{{ element.form }} ø{{ element.durchmesser }}</li>
              <li>{{ element.menge }} Stück</li>
            </ul>
          </div>
        </draggable>

        <p>
          Bearbeitungszeit: 2 Stunden
        </p>
        Aufträge: {{ listen.liste10.length }}
        <p>Stück: {{ arbeitsgaenge[0].stueckGesamt }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import moment from "moment";

export default {
  name: "TafelSystem",
  display: "Two list header slot",
  components: {
    draggable
  },
  mounted() {
    this.sortAuftraege();
  },
  computed: {
    ag() {
      return (
        this.$store.state.workload.pa.filter(pa => pa.kw == this.currentWeek) ||
        []
      );
    }
  },
  methods: {
    emptyLists() {
      this.listen.liste1 = [];
      this.listen.liste2 = [];
      this.listen.liste3 = [];
      this.listen.liste4 = [];
      this.listen.liste5 = [];
      this.listen.liste6 = [];
      this.listen.liste7 = [];
      this.listen.liste8 = [];
      this.listen.liste9 = [];
      this.listen.liste10 = [];
    },
    sortAuftraege() {
      this.emptyLists();

      this.ag.map(ags => {
        ags.ag.map(agss => {
          agss.id === 1 && agss.current && this.listen.liste1.push(ags);
          agss.id === 2 && agss.current && this.listen.liste2.push(ags);
          agss.id === 3 && agss.current && this.listen.liste3.push(ags);
          agss.id === 4 && agss.current && this.listen.liste4.push(ags);
          agss.id === 5 && agss.current && this.listen.liste5.push(ags);
          agss.id === 6 && agss.current && this.listen.liste6.push(ags);
          agss.id === 7 && agss.current && this.listen.liste7.push(ags);
          agss.id === 8 && agss.current && this.listen.liste8.push(ags);
          agss.id === 9 && agss.current && this.listen.liste9.push(ags);
          agss.id === 10 && agss.current && this.listen.liste10.push(ags);
        });
      });
    },
    dateByWeek() {
      moment.updateLocale("de", {
        week: {
          dow: 1
        }
      });
      const from_date = moment()
        .week(this.currentWeek)
        .startOf("isoWeek")
        .isoWeekday(1)
        .format("YYYY-MM-DD")
        .toLocaleString("de");
      const to_date = moment()
        .week(this.currentWeek)
        .endOf("isoWeek")
        .isoWeekday(5)
        .format("YYYY-MM-DD")
        .toLocaleString("de");
      return {
        fromDate: from_date,
        toDate: to_date
      };
    },
    showEditMenu(evt) {
      console.log("showEditMenu", evt);
    },
    checkMove(evt) {
      console.log("checkMove", evt);
    },
    onEnd(evt) {
      console.log("onEnd", evt);
    }
  },
  watch: {
    ag() {
      this.sortAuftraege();
    }
  },
  data() {
    return {
      currentWeek: moment().week(),
      produktionsplan: [],
      auftraege: [],
      arbeitsgaenge: [
        { name: "GK Mischen/Pressen", id: 1, stueckGesamt: 0 },
        { name: "Sägen", id: 2, stueckGesamt: 0 },
        { name: "Vordrehen", id: 3, stueckGesamt: 0 },
        { name: "Mischen", id: 4, stueckGesamt: 0 },
        { name: "Pressen", id: 5, stueckGesamt: 0 },
        { name: "Fertigdrehen", id: 6, stueckGesamt: 0 },
        { name: "Fräsen", id: 7, stueckGesamt: 0 },
        { name: "Schleifen", id: 8, stueckGesamt: 0 },
        { name: "Strahlen", id: 9, stueckGesamt: 0 },
        { name: "Lasern", id: 10, stueckGesamt: 0 }
      ],
      listen: {
        liste1: [],
        liste2: [],
        liste3: [],
        liste4: [],
        liste5: [],
        liste6: [],
        liste7: [],
        liste8: [],
        liste9: [],
        liste10: []
      }
    };
  }
};
</script>

<style scoped lang="scss">
.list-group-container {
  @apply bg-gray-200;
  @apply rounded-md;
  @apply p-2;
  @apply flex;
  @apply flex-col;

  p {
    @apply mt-4;
    @apply text-xs;
    @apply text-gray-700;
  }
}

.list-group {
  min-height: 200px;

  @apply border-dashed;
  @apply border-gray-400;
  @apply rounded-md;
  @apply border-2;
  @apply px-2;
  @apply flex-1;
}

.item {
  @apply rounded-md;
  @apply bg-white;
  @apply shadow-md;
  @apply my-2;
  @apply hover:bg-gray-50;
  @apply hover:border-primary-700;
  @apply cursor-move;
  @apply border;
  @apply border-gray-300;
  @apply text-gray-800;
  @apply text-sm;

  h4 {
    @apply rounded-t-md;
    @apply px-2;
    @apply py-1;
    @apply bg-green-400;

    &.eilig {
      @apply bg-red-400;
    }

    &.rohlinge {
      @apply bg-yellow-400;
    }

    &.gk {
      @apply bg-blue-400;
    }
  }

  ul {
    @apply p-2;
  }
}

.ghost {
  background: crimson;
  @apply bg-red-900;
}

h3 {
  @apply text-gray-800;
  @apply mb-4;
  @apply text-sm;
}
</style>
