var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v("Produktionsplanung KW "+_vm._s(_vm.currentWeek))]),_c('div',{staticClass:"mb-4 flex items-end gap-4"},[_c('label',[_vm._v(" Kalenderwoche "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentWeek),expression:"currentWeek"}],staticStyle:{"max-width":"100px"},attrs:{"type":"number","max":"53","min":"1"},domProps:{"value":(_vm.currentWeek)},on:{"input":function($event){if($event.target.composing){ return; }_vm.currentWeek=$event.target.value}}})]),_c('input',{attrs:{"type":"date","name":"vonDatum","id":"vonDatum"},domProps:{"value":_vm.dateByWeek.fromDate}}),_c('input',{attrs:{"type":"date","name":"bisDatum","id":"bisDatum"},domProps:{"value":_vm.dateByWeek.toDate}})]),_c('div',{staticClass:"grid gap-2 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6",class:'2xl:grid-cols-' + _vm.arbeitsgaenge.length},[_c('div',{staticClass:"list-group-container"},[_c('h3',[_vm._v(_vm._s(_vm.arbeitsgaenge[0].name))]),_c('draggable',{staticClass:"list-group",attrs:{"move":_vm.checkMove,"id":_vm.arbeitsgaenge[0].id,"list":_vm.listen.liste1,"draggable":".item","group":"a"},on:{"end":_vm.onEnd}},_vm._l((_vm.listen.liste1),function(element,index){return _c('router-link',{key:element.id,attrs:{"to":element.id}},[_c('div',{staticClass:"list-group-item item"},[_c('h4',{class:[
                element.rohlinge && 'rohlinge',
                element.eilig && 'eilig',
                element.material === 'gk' && 'gk'
              ],on:{"mouseover":function($event){return _vm.showEditMenu(index)}}},[_vm._v(" "+_vm._s(element.belegnr)+" ")]),_c('ul',[_c('li',[_vm._v(_vm._s(element.kunde))]),_c('li',[_vm._v(_vm._s(element.form)+" ø"+_vm._s(element.durchmesser))]),_c('li',[_vm._v(_vm._s(element.menge)+" Stück")])])])])}),1),_c('p',[_vm._v(" Bearbeitungszeit: 2 Stunden ")]),_vm._v(" Aufträge: "+_vm._s(_vm.listen.liste1.length)+" ")],1),_c('div',{staticClass:"list-group-container"},[_c('h3',[_vm._v(_vm._s(_vm.arbeitsgaenge[1].name))]),_c('draggable',{staticClass:"list-group",attrs:{"move":_vm.checkMove,"id":_vm.arbeitsgaenge[1].id,"list":_vm.listen.liste2,"draggable":".item","group":"a"},on:{"end":_vm.onEnd}},_vm._l((_vm.listen.liste2),function(element,index){return _c('router-link',{key:element.id,attrs:{"to":("/pa/" + (element.auftragsnr))}},[_c('div',{staticClass:"list-group-item item"},[_c('h4',{class:[
                element.rohlinge && 'rohlinge',
                element.eilig && 'eilig',
                element.material === 'gk' && 'gk'
              ],on:{"mouseover":function($event){return _vm.showEditMenu(index)}}},[_vm._v(" "+_vm._s(element.belegnr)+" ")]),_c('ul',[_c('li',[_vm._v(_vm._s(element.kunde))]),_c('li',[_vm._v(_vm._s(element.form)+" ø"+_vm._s(element.durchmesser))]),_c('li',[_vm._v(_vm._s(element.menge)+" Stück")])])])])}),1),_c('p',[_vm._v(" Bearbeitungszeit: 2 Stunden ")]),_vm._v(" Aufträge: "+_vm._s(_vm.listen.liste2.length)+" ")],1),_c('div',{staticClass:"list-group-container"},[_c('h3',[_vm._v(_vm._s(_vm.arbeitsgaenge[2].name))]),_c('draggable',{staticClass:"list-group",attrs:{"move":_vm.checkMove,"id":_vm.arbeitsgaenge[2].id,"list":_vm.listen.liste3,"draggable":".item","group":"a"},on:{"end":_vm.onEnd}},_vm._l((_vm.listen.liste3),function(element,index){return _c('div',{key:element.id,staticClass:"list-group-item item"},[_c('h4',{class:[
              element.rohlinge && 'rohlinge',
              element.eilig && 'eilig',
              element.material === 'gk' && 'gk'
            ],on:{"mouseover":function($event){return _vm.showEditMenu(index)}}},[_vm._v(" "+_vm._s(element.belegnr)+" ")]),_c('ul',[_c('li',[_vm._v(_vm._s(element.kunde))]),_c('li',[_vm._v(_vm._s(element.form)+" ø"+_vm._s(element.durchmesser))]),_c('li',[_vm._v(_vm._s(element.menge)+" Stück")])])])}),0),_c('p',[_vm._v(" Bearbeitungszeit: 2 Stunden ")]),_vm._v(" Aufträge: "+_vm._s(_vm.listen.liste3.length)+" ")],1),_c('div',{staticClass:"list-group-container"},[_c('h3',[_vm._v(_vm._s(_vm.arbeitsgaenge[3].name))]),_c('draggable',{staticClass:"list-group",attrs:{"move":_vm.checkMove,"id":_vm.arbeitsgaenge[3].id,"list":_vm.listen.liste4,"draggable":".item","group":"a"},on:{"end":_vm.onEnd}},_vm._l((_vm.listen.liste4),function(element,index){return _c('div',{key:element.id,staticClass:"list-group-item item"},[_c('h4',{class:[
              element.rohlinge && 'rohlinge',
              element.eilig && 'eilig',
              element.material === 'gk' && 'gk'
            ],on:{"mouseover":function($event){return _vm.showEditMenu(index)}}},[_vm._v(" "+_vm._s(element.belegnr)+" ")]),_c('ul',[_c('li',[_vm._v(_vm._s(element.kunde))]),_c('li',[_vm._v(_vm._s(element.form)+" ø"+_vm._s(element.durchmesser))]),_c('li',[_vm._v(_vm._s(element.menge)+" Stück")])])])}),0),_c('p',[_vm._v(" Bearbeitungszeit: 2 Stunden ")]),_vm._v(" Aufträge: "+_vm._s(_vm.listen.liste4.length)+" ")],1),_c('div',{staticClass:"list-group-container"},[_c('h3',[_vm._v(_vm._s(_vm.arbeitsgaenge[4].name))]),_c('draggable',{staticClass:"list-group",attrs:{"move":_vm.checkMove,"id":_vm.arbeitsgaenge[4].id,"list":_vm.listen.liste5,"draggable":".item","group":"a"},on:{"end":_vm.onEnd}},_vm._l((_vm.listen.liste5),function(element,index){return _c('div',{key:element.id,staticClass:"list-group-item item"},[_c('h4',{class:[
              element.rohlinge && 'rohlinge',
              element.eilig && 'eilig',
              element.material === 'gk' && 'gk'
            ],on:{"mouseover":function($event){return _vm.showEditMenu(index)}}},[_vm._v(" "+_vm._s(element.belegnr)+" ")]),_c('ul',[_c('li',[_vm._v(_vm._s(element.kunde))]),_c('li',[_vm._v(_vm._s(element.form)+" ø"+_vm._s(element.durchmesser))]),_c('li',[_vm._v(_vm._s(element.menge)+" Stück")])])])}),0),_c('p',[_vm._v(" Bearbeitungszeit: 2 Stunden ")]),_vm._v(" Aufträge: "+_vm._s(_vm.listen.liste5.length)+" ")],1),_c('div',{staticClass:"list-group-container"},[_c('h3',[_vm._v(_vm._s(_vm.arbeitsgaenge[5].name))]),_c('draggable',{staticClass:"list-group",attrs:{"move":_vm.checkMove,"id":_vm.arbeitsgaenge[5].id,"list":_vm.listen.liste6,"draggable":".item","group":"a"},on:{"end":_vm.onEnd}},_vm._l((_vm.listen.liste6),function(element,index){return _c('div',{key:element.id,staticClass:"list-group-item item"},[_c('h4',{class:[
              element.rohlinge && 'rohlinge',
              element.eilig && 'eilig',
              element.material === 'gk' && 'gk'
            ],on:{"mouseover":function($event){return _vm.showEditMenu(index)}}},[_vm._v(" "+_vm._s(element.belegnr)+" ")]),_c('ul',[_c('li',[_vm._v(_vm._s(element.kunde))]),_c('li',[_vm._v(_vm._s(element.form)+" ø"+_vm._s(element.durchmesser))]),_c('li',[_vm._v(_vm._s(element.menge)+" Stück")])])])}),0),_c('p',[_vm._v(" Bearbeitungszeit: 2 Stunden ")]),_vm._v(" Aufträge: "+_vm._s(_vm.listen.liste6.length)+" ")],1),_c('div',{staticClass:"list-group-container"},[_c('h3',[_vm._v(_vm._s(_vm.arbeitsgaenge[6].name))]),_c('draggable',{staticClass:"list-group",attrs:{"move":_vm.checkMove,"id":_vm.arbeitsgaenge[6].id,"list":_vm.listen.liste7,"draggable":".item","group":"a"},on:{"end":_vm.onEnd}},_vm._l((_vm.listen.liste7),function(element,index){return _c('div',{key:element.id,staticClass:"list-group-item item"},[_c('h4',{class:[
              element.rohlinge && 'rohlinge',
              element.eilig && 'eilig',
              element.material === 'gk' && 'gk'
            ],on:{"mouseover":function($event){return _vm.showEditMenu(index)}}},[_vm._v(" "+_vm._s(element.belegnr)+" ")]),_c('ul',[_c('li',[_vm._v(_vm._s(element.kunde))]),_c('li',[_vm._v(_vm._s(element.form)+" ø"+_vm._s(element.durchmesser))]),_c('li',[_vm._v(_vm._s(element.menge)+" Stück")])])])}),0),_c('p',[_vm._v(" Bearbeitungszeit: 2 Stunden ")]),_vm._v(" Aufträge: "+_vm._s(_vm.listen.liste7.length)+" ")],1),_c('div',{staticClass:"list-group-container"},[_c('h3',[_vm._v(_vm._s(_vm.arbeitsgaenge[7].name))]),_c('draggable',{staticClass:"list-group",attrs:{"move":_vm.checkMove,"id":_vm.arbeitsgaenge[7].id,"list":_vm.listen.liste8,"draggable":".item","group":"a"},on:{"end":_vm.onEnd}},_vm._l((_vm.listen.liste8),function(element,index){return _c('div',{key:element.id,staticClass:"list-group-item item"},[_c('h4',{class:[
              element.rohlinge && 'rohlinge',
              element.eilig && 'eilig',
              element.material === 'gk' && 'gk'
            ],on:{"mouseover":function($event){return _vm.showEditMenu(index)}}},[_vm._v(" "+_vm._s(element.belegnr)+" ")]),_c('ul',[_c('li',[_vm._v(_vm._s(element.kunde))]),_c('li',[_vm._v(_vm._s(element.form)+" ø"+_vm._s(element.durchmesser))]),_c('li',[_vm._v(_vm._s(element.menge)+" Stück")])])])}),0),_c('p',[_vm._v(" Bearbeitungszeit: 2 Stunden ")]),_vm._v(" Aufträge: "+_vm._s(_vm.listen.liste8.length)+" ")],1),_c('div',{staticClass:"list-group-container"},[_c('h3',[_vm._v(_vm._s(_vm.arbeitsgaenge[8].name))]),_c('draggable',{staticClass:"list-group",attrs:{"move":_vm.checkMove,"id":_vm.arbeitsgaenge[8].id,"list":_vm.listen.liste9,"draggable":".item","group":"a"},on:{"end":_vm.onEnd}},_vm._l((_vm.listen.liste9),function(element,index){return _c('div',{key:element.id,staticClass:"list-group-item item"},[_c('h4',{class:[
              element.rohlinge && 'rohlinge',
              element.eilig && 'eilig',
              element.material === 'gk' && 'gk'
            ],on:{"mouseover":function($event){return _vm.showEditMenu(index)}}},[_vm._v(" "+_vm._s(element.belegnr)+" ")]),_c('ul',[_c('li',[_vm._v(_vm._s(element.kunde))]),_c('li',[_vm._v(_vm._s(element.form)+" ø"+_vm._s(element.durchmesser))]),_c('li',[_vm._v(_vm._s(element.menge)+" Stück")])])])}),0),_c('p',[_vm._v(" Bearbeitungszeit: 2 Stunden ")]),_vm._v(" Aufträge: "+_vm._s(_vm.listen.liste9.length)+" ")],1),_c('div',{staticClass:"list-group-container"},[_c('h3',[_vm._v(_vm._s(_vm.arbeitsgaenge[9].name))]),_c('draggable',{staticClass:"list-group",attrs:{"move":_vm.checkMove,"id":_vm.arbeitsgaenge[9].id,"list":_vm.listen.liste10,"draggable":".item","group":"a"},on:{"end":_vm.onEnd}},_vm._l((_vm.listen.liste10),function(element,index){return _c('div',{key:element.id,staticClass:"list-group-item item"},[_c('h4',{class:[
              element.rohlinge && 'rohlinge',
              element.eilig && 'eilig',
              element.material === 'gk' && 'gk'
            ],on:{"mouseover":function($event){return _vm.showEditMenu(index)}}},[_vm._v(" "+_vm._s(element.belegnr)+" ")]),_c('ul',[_c('li',[_vm._v(_vm._s(element.kunde))]),_c('li',[_vm._v(_vm._s(element.form)+" ø"+_vm._s(element.durchmesser))]),_c('li',[_vm._v(_vm._s(element.menge)+" Stück")])])])}),0),_c('p',[_vm._v(" Bearbeitungszeit: 2 Stunden ")]),_vm._v(" Aufträge: "+_vm._s(_vm.listen.liste10.length)+" "),_c('p',[_vm._v("Stück: "+_vm._s(_vm.arbeitsgaenge[0].stueckGesamt))])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }